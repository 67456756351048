<script setup>
import { onMounted, reactive, ref, defineProps, defineEmits } from "vue";
import { useRoute } from "vue-router";
const route = useRoute(); // useRoter không sử dụng được .name
import MethodService from "../service/MethodService";
import toastr from "toastr";
import mushroom from "cem-probe-api";
import { VueDraggableNext } from "vue-draggable-next";
import LanguageService from "../service/LanguageService";
import { langWeb } from "../stores/lang";
const lang_web = langWeb()
const lang = ref(lang_web.showLang.toUpperCase())
let loading = ref(true);
const dialogVisible = ref(true);
const dataTables = reactive({ value: [] }); // data bảng
const emit = defineEmits([""]);
const props = defineProps({
  dashboardType: String,
  dashboardServiceId: String,
});
// Lấy danh sách dịch vụ
const getService = async () => {
  let dataFilter = {
    filters: props.dashboardServiceId
      ? [
          "dashboard=" + props.dashboardType,
          route.name === "DichVu"
            ? "service_id=" + props.dashboardServiceId
            : "dynamic_dashboard_id=" + props.dashboardServiceId,
        ]
      : ["dashboard=" + props.dashboardType],
  };
  try {
    const serviceApiRes = await mushroom.card.getAllAsync(dataFilter);
    if (serviceApiRes.result) {
      dataTables.value = serviceApiRes.result;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

// cập nhật dịch vụ
const updateService = async (listService) => {
  const listPromise = listService.map((service, i) =>
    mushroom.card.partialUpdateAsync({
      id: service.id,
      index: i,
    })
  );

  try {
    await Promise.all(listPromise);
    toastr.success(LanguageService[lang.value].toastr_update_success);
  } catch (error) {
    MethodService.showError(error.message); // TODO: Cần hiển thị message tốt hơn
  }
};

const changeIndexService = async () => {
  await updateService(dataTables.value);
  emit("reloadGridDashboard");
  emit("cancelShowSortCard");
};

const cancelChangeIndex = () => {
  emit("cancelShowSortCard");
};
const handleClose = () => {
  emit("cancelShowSortCard");
};

onMounted(async () => {
  await getService();
});
</script>

<template>
  <el-dialog
    v-model="dialogVisible"
    :title="$t('t-config-display-card')"
    width="100%"
    class="dialog-sort"
    style="max-width: 1000px"
    :before-close="handleClose"
    align-center
  >
    <div class="sort-card">
      <div class="card">
        <div class="card-body">
          <div class="main-body" v-loading="loading">
            <VueDraggableNext
              class="dragArea list-group w-full"
              :list="dataTables.value"
            >
              <transition-group name="listService">
                <div
                  class="list-group-item bg-gray-300 m-1 p-1 rounded-md text-center"
                  v-for="el in dataTables.value"
                  :key="el.id"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      cursor: pointer;
                      padding: 10px;
                    "
                  >
                    <span>
                      <i
                        class="bx bx-move label-icon align-middle fs-16 me-2"
                      ></i>
                      {{ el.title }}</span
                    >
                  </div>
                </div>
              </transition-group>
            </VueDraggableNext>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-4">
      <button
        type="button"
        class="btn btn-soft-secondary btn-border me-2"
        @click="cancelChangeIndex"
      >
        {{ $t("t-cancel") }}
      </button>
      <button
        type="button"
        class="btn btn-secondary btn-border"
        @click="changeIndexService"
      >
        {{ $t("t-update") }}
      </button>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.dialog-sort {
  .el-dialog__body {
    padding-bottom: 20px;
  }

  .listService-move,
/* apply transition to moving elements */
.listService-enter-active,
.listService-leave-active {
    transition: all 0.5s ease;
  }
}
</style>
